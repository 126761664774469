<template>
  <ag-grid-vue
    v-bind="$attrs"
    class="yb-grid"
    :class="theme"
    :modules="modules"
    :grid-options="resolvedGridOptions"
    :default-col-def="defaultColDef"
    :column-defs="resolvedColumnDefs"
    :row-drag-managed="true"
    :row-selection="rowSelection"
    @grid-ready="onGridReady"
    @model-updated="modelUpdated"
    @selection-changed="selectionChanged"
    @sort-changed="sortChanged"
    @column-moved="columnMoved"
    @row-double-clicked="rowDoubleClicked"
  />
</template>

<script>

import { AgGridVue } from '@ag-grid-community/vue3'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'

export default {
  components: {
    AgGridVue
  },
  props: {
    rows: {
      type: [Array, Function] // Either a fixed array of rows, or a function returning a Promise<Array>
    },
    columnDefs: Array,
    sortModel: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    columnId: {
      type: String,
      requird: false
    },
    searchExpression: String,
    noRowsToShow: {
      type: String,
      default: 'No results to display'
    },
    rowHeight: {
      type: Number,
      default: 40
    },
    headerHeight: {
      type: Number,
      default: 40
    },
    gridOptions: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    hideInfiniteScrollLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    rowSelection: {
      type: String,
      default: 'single'
    },
    infiniteInitialRowCount: {
      type: Number,
      default: 100
    },
    cacheBlockSize: {
      type: Number,
      default: 100
    }
  },
  emits: ['columnMoved', 'sortChanged', 'ready', 'load', 'grid-ready', 'select', 'dblclick'],
  data() {
    const resolvedGridOptions = Object.assign({
      headerHeight: this.headerHeight,
      rowHeight: this.rowHeight,
      rowStyle: {
        'border-width': '1px',
        'border-left-color': 'transparent',
        'border-right-color': 'transparent',
        'border-top-color': 'transparent'
      },
      localeText: {
        noRowsToShow: this.noRowsToShow
      }
    }, (this.gridOptions || {}))
    const baseGridData = {
      loading: false,
      itemCount: Array.isArray(this.rows) ? this.rows.length : 0,
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        flex: 1,
        cellStyle: {
          'line-height': `${this.rowHeight}px`
        }
      },
      selectedIndex: -1
    }
    if (Array.isArray(this.rows)) {
      return {
        ...baseGridData,
        ...{
          modules: [ClientSideRowModelModule],
          resolvedGridOptions
        }
      }
    } else {
      return {
        ...baseGridData,
        ...{
          modules: [InfiniteRowModelModule],
          resolvedGridOptions: {
            ...resolvedGridOptions,
            ...{
              // Infinite row model settings.
              // Source: https://www.ag-grid.com/documentation/javascript/infinite-scrolling/

              rowBuffer: 0,
              // tell grid we want virtual row model type
              rowModelType: 'infinite',
              // how big each page in our page cache will be, default is 100
              paginationPageSize: 50,
              // how many extra blank rows to display to the user at the end of the dataset,
              // which sets the vertical scroll and then allows the grid to request viewing more rows of data.
              // default is 1, ie show 1 row.
              cacheOverflowSize: 2,
              // how many server side requests to send at a time. if user is scrolling lots, then the requests
              // are throttled down
              maxConcurrentDatasourceRequests: 1,
              // how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
              // the grid is loading from the users perspective(as we have a spinner in the first col)
              infiniteInitialRowCount: this.infiniteInitialRowCount,
              // how many pages to store in cache. default is undefined, which allows an infinite sized cache,
              // pages are never purged. this should be set for large data to stop your browser from getting
              // full of data
              maxBlocksInCache: 10,
              // How many rows for each block in the store, i.e. how many rows returned from the server at a time.
              cacheBlockSize: this.cacheBlockSize
            }
          }
        }
      }
    }
  },
  computed: {
    infinite() {
      return !!this.rows && !Array.isArray(this.rows)
    },
    rowData() {
      return Array.isArray(this.rows) ? this.rows : null
    },
    theme() {
      return 'ag-theme-alpine'
    },
    resolvedColumnDefs() {
      let { columnDefs } = this
      columnDefs ??= []
      return columnDefs.map(c => {
        if (c) {
          c.headerTooltip ??= c.headerName
        }
        return c
      })
    }
  },
  watch: {
    searchExpression(_) {
      if (this.gridApi) {
        !this.infinite && this.gridApi.setQuickFilter(_)
      }
    },
    rows(_) {
      if (!!_ && Array.isArray(_)) {
        this.itemCount = _.length
        if (this.gridApi) {
          this.gridApi.setRowData(_)
          this.reselectRows()
        }
      }
    }
  },
  mounted() {
    this.gridApi = this.resolvedGridOptions.api
    this.gridColumnApi = this.resolvedGridOptions.columnApi
    this.reset()
    this.$emit('ready')
    if (this.gridApi && !this.infinite && !!this.rows && Array.isArray(this.rows)) {
      this.gridApi.setRowData(this.rows)
    }

    // Adapted to allow side-scrolling when mouse is over the header from https://github.com/ag-grid/ag-grid/issues/2634
    this.$nextTick(() => {
      const headerViewport = this.$el.querySelector('.ag-header-viewport')
      if (!!headerViewport) {
        headerViewport.addEventListener('wheel', (e) => {
          e.preventDefault()
          const left = headerViewport.scrollLeft + e.deltaX
          const viewport = this.$el.querySelector('[ref="eViewport"]')
          if (!!viewport)
            viewport.scrollLeft = left
        })
      }
    })
  },
  beforeUnmount() {
    delete this.gridApi
    delete this.gridColumnApi
  },
  methods: {
    reset() {
      if (!this.gridApi) {
        return
      }
      if (!this.infinite) {
        this.gridApi.refreshCells({ force: true, suppressFlash: false })
      } else {
        this.loading = true
        this.itemCount = 0
        this.gridApi.setDatasource({
          getRows: this.getRows.bind(this),
          sortModel: this.sortModel
        })
      }
      this.$emit('load')
    },
    async getRows(params) {
      if (!this.gridApi) {
        return
      }
      if (typeof this.rows !== 'function') {
        throw new TypeError(`'rows' property must be a function to retrieve rows for infinite row model`)
      }
      if (this.gridApi && !this.hideInfiniteScrollLoading) {
        this.gridApi.showLoadingOverlay()
      }
      try {
        if (params.startRow === 0) {
          this.itemCount = 0
        }
        const offset = params.startRow
        const limit = params.endRow - params.startRow
        const filter = Object.keys(params?.filterModel || {}).length === 0 ? null : params.filterModel
        const rows = (await this.rows(limit, offset, params.sortModel, filter)) || []
        const { __total_rows__ } = rows
        params.successCallback(rows, __total_rows__ || (rows.length === limit ? -1 : rows.length + this.itemCount))
        this.itemCount += rows.length
        this.$emit('load')
      } catch (e) {
        console.log('Error loading data', e)
        console.error(e)
        params.failCallback(e)
      } finally {
        this.loading = false
        if (this.gridApi && !this.hideInfiniteScrollLoading) {
          this.gridApi.hideOverlay()
        }
      }
    },
    onGridReady(params) {
      this.$emit('grid-ready', params)
    },
    selectionChanged($event) {
      if (!this.gridApi) {
        return
      }
      this.selectedRows = this.gridApi.getSelectedRows()
      const nodes = this.gridApi.getSelectedNodes()
      if (nodes && nodes.length > 0) {
        this.selectedIndex = nodes[0].rowIndex
      } else {
        this.selectedIndex = -1
      }
      this.$emit('select', this.selectedRows)
    },
    modelUpdated() {
      if (this.infinite) {
        this.reselectRows()
      }
    },
    reselectRows() {
      const { columnId } = this
      if (!!columnId && !!this.selectedRows) {
        this.selectRows(this.selectedRows, (r1, r2) => {
          const v1 = !!r1 && r1[columnId]
          const v2 = !!r2 && r2[columnId]
          return v1 === v2
        })
      }
    },
    sortChanged($event) {
      if (!this.gridApi) {
        return
      }
      this.$emit('sortChanged', $event.columnApi.getColumnState())
    },
    rowDoubleClicked($event) {
      if (!this.gridApi) {
        return
      }
      this.$emit('dblclick', this.gridApi.getSelectedRows())
    },
    columnMoved($event) {
      this.$emit('columnMoved', $event)
    },
    setLoading(_) {
      if (!this.gridApi) {
        return
      }
      if (_) {
        this.gridApi.showLoadingOverlay()
      } else {
        this.gridApi.hideOverlay()
      }
    },
    setRowsHeight() { // derived from: https://stackoverflow.com/questions/44820410/getrowheight-not-working-with-rowmodeltype-infinite-with-latest-ag-grid-ve
      if (!this.gridApi) {
        return
      }
      let gridHeight = 0

      this.gridApi.forEachNode((node) => {
        const rowHeight = this.gridOptions.getRowHeight(node)

        node.setRowHeight(rowHeight)
        node.setRowTop(gridHeight)

        gridHeight += rowHeight
      })
      if (!gridHeight) {
        return
      }

      const agFullWidthContainer = this.$el.getElementsByClassName('ag-full-width-container')
      if (!!agFullWidthContainer && !!agFullWidthContainer.length) {
        agFullWidthContainer[0].style.height = `${gridHeight}px`
      }
    },
    selectNodes(selectedNodes, comparatorFn) {
      if (!this.gridApi) {
        return
      }
      this.gridApi.forEachNode((node) => {
        const selectNode = selectedNodes.some(n => comparatorFn(n, node))
        if (selectNode) {
          node.setSelected(true, false)
        }
      })
    },
    selectRows(selectedRows, comparatorFn) {
      if (!this.gridApi) {
        return
      }
      if (!comparatorFn) {
        const { columnId } = this
        comparatorFn = (d1, d2) => d1[columnId] === d2[columnId]
      }
      this.gridApi.forEachNode((node) => {
        const selectNode = selectedRows.some(r => comparatorFn(r, node.data))
        if (selectNode) {
          node.setSelected(true, false)
        }
      })
    },
    deselectAll() {
      if (!this.gridApi) {
        return
      }
      this.gridApi.deselectAll()
    },
    selectOffset(offset) {
      // Get current selection and find next.
      let result
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes && selectedNodes.length >= 1) {
        const selectedNode = selectedNodes[0]
        this.gridApi.forEachNode((node) => {
          if (node.rowIndex === (selectedNode.rowIndex + offset)) {
            this.deselectAll()
            node.setSelected(true, false)
            this.selectedIndex = node.rowIndex
            result = node.data
          }
        })
      }
      return result
    },
    selectNext() {
      if (!this.gridApi || !this.selectedRows || !this.selectedRows.length) {
        return
      }

      return this.selectOffset(1)
    },
    selectPrior() {
      let result
      if (!this.gridApi || !this.selectedRows || !this.selectedRows.length) {
        return result
      }

      return this.selectOffset(-1)
    }
  }
}
</script>

<style lang="postcss">
@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-alpine .ag-root-wrapper {
  @apply yb-border-content;
}

.ag-theme-alpine .ag-root-wrapper {
  @apply !yb-border-content dark:border-yb-gray-alt-lightest-inverse;
}

.ag-theme-alpine .ag-root {
  @apply yb-bg-content;
}

.ag-theme-alpine div {
  @apply text-sm leading-none;
}

.ag-theme-alpine div.ag-header {
  @apply yb-border-content dark:border-yb-gray-alt-lightest-inverse dark:bg-yb-gray-alt-lightest-inverse;
}

.ag-theme-alpine div.ag-header-row {
  @apply h-8;
}

.ag-theme-alpine div.ag-row-hover:not(.ag-row-selected) {
  @apply !bg-yb-gray-alt-lightest dark:!bg-yb-gray-mediumer;
}

.ag-theme-alpine div.ag-row-hover:not(.ag-row-selected)::before {
  background-color: transparent !important;
}

.ag-theme-alpine .ag-row-hover.ag-row-selected::before {
  background-color: transparent !important;
  background-image: none !important;
}

.ag-theme-alpine div.ag-row-selected {
  @apply bg-yb-gray-faint dark:!bg-yb-gray-alt-medium dark:!text-yb-gray-alt-lightest;
  @apply !border-yb-gray-alt-lighter !border-0;
}

.ag-theme-alpine .ag-row-selected::before {
  background-color: transparent !important;
}

.ag-theme-alpine .ag-ltr div.ag-cell {
  @apply !border-0;
}

.ag-theme-alpine div.ag-cell:first-child {
  @apply !border-l-transparent !border-l-4 !border-t-0 !border-r-0 !border-b-0;
}

.ag-theme-alpine div.ag-row-selected div.ag-cell:first-child:not(.yb-cell-disable-select-emphasis) {
  @apply !border-l-yb-brand-primary;
}

.ag-theme-alpine div.ag-row:not(.ag-row-selected) {
  @apply !border-0;
}

.ag-theme-alpine div.ag-header-cell-label {
  @apply text-base;
}

.ag-theme-alpine .ag-cell-focus:not(.ag-cell:first-child),
.ag-theme-alpine .ag-cell-no-focus {
  border:none !important;
}

.ag-theme-alpine .ag-row:not(.ag-row-selected),
.ag-theme-alpine .ag-row-odd:not(.ag-row-selected)  {
  @apply yb-bg-content text-yb-gray-medium dark:text-yb-gray-lightest;
}

.ag-theme-alpine .ag-row .ag-cell.center {
  @apply inline-block justify-self-center items-center text-center truncate;
  vertical-align: middle;
}

.ag-theme-alpine .ag-row .ag-cell.ag-right-aligned-cell.center {
  @apply justify-self-end;
}

.ag-theme-alpine .ag-selection-checkbox.ag-invisible {
  @apply !hidden;
}

.ag-theme-alpine {
  @apply font-sans;

  & .ag-header-cell-text {
    font-weight: 600;
    font-size: 90%;
  }
}

.ag-theme-alpine .ag-header-row.ag-header-row-column,
.ag-theme-alpine .ag-header-viewport {
  @apply yb-header;
}

.ag-theme-alpine .ag-overlay-loading-wrapper {
  @apply dark:bg-yb-gray-alt-dark;
}

.ag-theme-alpine .ag-overlay-loading-center {
  @apply dark:bg-black dark:text-white font-semibold;
}

.ag-theme-alpine .ag-overlay-no-rows-center {
  @apply text-lg dark:text-white;
}

.ag-theme-alpine .ag-group-value {
  @apply h-full;
}

.ag-theme-alpine .ag-checkbox-input {
  @apply !-mt-4;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  @apply border-0 bg-transparent;
}

.ag-theme-alpine .ag-checkbox-input-wrapper:focus-within, .ag-theme-alpine .ag-checkbox-input-wrapper:active {
  box-shadow: 0 0 2px 0.1rem rgba(134, 188, 77, 0.4) !important;
  @apply border-0 bg-transparent;
  width: 14px;
  height: 14px;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  @apply dark:bg-black dark:rounded text-yb-brand-primary;
}

:root {
  --ag-alpine-active-color: rgb(134, 188, 77);
}

.ag-theme-alpine .ag-cell-value {
  @apply pt-0 pb-1;
}

.ag-theme-alpine .ag-cell-wrapper {
  @apply w-full;
}

.ag-theme-alpine .ag-ltr .ag-row-group-leaf-indent {
  @apply ml-0;
}

</style>

<style lang="postcss">

.yb-grid * {
  transition: none !important;
}

</style>
